// OfficeAddress.js
import React from 'react';

const ContactFormArea = () => {
  return (
    <div className="container mt-5 mb-5">
     <h1>Contact Form Area</h1>
    </div>
  );
}

export default ContactFormArea;
