import React from 'react'

function MorroccoVisa() {
  return (
    <div>
      <h1>MorroccoVisa</h1>
    </div>
  )
}

export default MorroccoVisa