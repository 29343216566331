// OfficeAddress.js
import React from 'react';

const EventArea = () => {
  return (
    <div className="container mt-5 mb-5">
      <h1>Event Information</h1>
    </div>
  );
}

export default EventArea;
