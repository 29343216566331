import React from 'react'

function SchengenTourist() {
  return (
    <div>
      <h1>Schengen Tourist</h1>
    </div>
  )
}

export default SchengenTourist