import React from 'react'

function EastAfrica() {
  return (
    <div>
      <h1>East Africa Visa</h1>
    </div>
  )
}

export default EastAfrica